<template>
  <div>
    <DetailComponent :id="id" />
  </div>
</template>
<script>
const DetailComponent = () => import("./DetailComponent");

export default {
  components: {
    DetailComponent
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.employeeId : null
    };
  }
};
</script>
